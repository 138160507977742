#blog-image-thumbnail {
    width: 1096px;
    height: 188px;
    object-fit: cover;
    margin-left: 35px;
    margin-top: 35px;
}

@media(max-width: 991px) {
    #blog-image-thumbnail {
        width: 300px;
        height: 188px;
        object-fit: cover;
    }
}