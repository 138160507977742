.about-section p {
    font-family: Gotham Book;
    color: #000;
    font-weight: 100;
    font-size: 0.9rem;
}

.about-section h1 {
    font-family: Gotham;
    color: #000;
    margin-bottom: 50px;
    font-size: 30px;
    text-transform: uppercase;
}

.about-section img {
    box-sizing: border-box;
    max-width: 310%;
    max-height: 406px;
    width: 180%;
}

@media screen and (min-width: 1920px) {
    #about-image {
        left: 120px;
    }
}

@media screen and (max-width: 1200px) {
    .about-section {
        margin-bottom: -30px !important;
    }
    .about-section h1 {
        margin-bottom: 18px;
        margin-top: -10px;
    }
    .about-section img {
        max-width: 100%;
        box-sizing: border-box;
        max-height: 406px;
        width: 180%;
        right: 62px;
        position: relative;
    }
    #about-image {
        left: 80px;
    }
}

@media screen and (width: 932px) {
    .about-section img {
        right: 14px;
    }
}