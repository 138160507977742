#add-blog {
    /* background: url("add-image-here") center no-repeat local !important; */
    background-size: 1170px !important;
    background-position: 50% 50px !important;
}

.ql-editor p {
    color: #000;
}

.ql-editor {
    background-color: #fff;
}