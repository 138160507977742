.feature-section .section-padding {
    border-top: 5px black;
    margin-top: -5px;
}

.feature-section {
    background-color: #000;
    padding-bottom: 87px;
}

.feature-section::after {
    content: "";
    position: absolute;
    background-color: #000;
    height: 86px;
    width: 100%;
    left: 0;
    z-index: 0;
    transform: skewY(2deg); /* Diagonal shape on the bottom */
    transform-origin: bottom left;
}

.feature-section::before {
    content: "";
    position: absolute;
    background-color: #000;
    height: 100px;
    width: 100%;
    left: 0;
    z-index: 0;
    transform: skewY(-3deg); /* Diagonal shape on the bottom */
    transform-origin: top right;
    top: -100px;
}

.feature-section h2 {
    text-transform: uppercase;
    font-family: Gotham;
    font-size: 1.2rem;
    color: #fff;
    line-height: 1em;
    margin: 0 0 1em;
}

.read-more {
    font-family: "Gotham", serif;
    color: #687693;
    padding-left: 50px;
    position: absolute;
    bottom: 25px;
    right: 50px;
}

.section-title {
    color: #fa5235;
    font-family: "Gotham", serif;
    font-weight: 500;
}

.title-container {
    margin-bottom: 125px;
}

.feature-grid {
    height: 325px;
    text-align: center;
    align-items: center;
    background-color: #000;
}

.feature-grid > img{
    width: auto;
    max-width: 80%;
    height: 150px;
}

.feature-grid p a {
    all: unset;
    cursor: pointer;
}

/* SwiperSlide styling - every even slide */
@media screen and (min-width: 891px) {
        
    .feature-grid > img {
        height: auto;
    }

    .feature-grid {
        height: auto;
    }
}

@media screen and (min-width: 1200px) {
    .feature-section > .container > .row > .col:nth-child(odd) {
        border: 2px solid #fa5235;
    }

    .col-sm-6 {
        width: 25% !important;
    }

    .feature-grid > img {
        height: auto;
        max-width: 80%;
    }

    .feature-grid {
        height: auto;
    }
}

@media screen and (max-width: 850px) {
    .feature-grid {
        height: 100%;
    }

    .feature-grid > img {
        width: auto;
        height: 100%;
    }
}

@media screen and (max-width: 767px) {
    #feature-icons::before {
        content: none !important;
    }
    #feature-icons {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        content: none !important;
    }

    .feature-grid {
        height: 165px;
    }

    .feature-grid > img {
        width: auto;
        max-width: 75%;
        height: 130px;
    }

    .feature-grid > h2 {
        font-size: 0.8em;
    }

    .btn-group-vertical > .btn-group::after, .btn-group-vertical > .btn-group::before, .btn-toolbar::after, .btn-toolbar::before, .clearfix::after, .clearfix::before, .container-fluid::after, .container-fluid::before, .container::after, .container::before, .dl-horizontal dd::after, .dl-horizontal dd::before, .form-horizontal .form-group::after, .form-horizontal .form-group::before, .modal-footer::after, .modal-footer::before, .modal-header::after, .modal-header::before, .nav::after, .nav::before, .navbar-collapse::after, .navbar-collapse::before, .navbar-header::after, .navbar-header::before, .navbar::after, .navbar::before, .pager::after, .pager::before, .panel-body::after, .panel-body::before, .row::after, .row::before {
        content: none;
    }

    .btn-group-vertical > .btn-group::after, .btn-group-vertical > .btn-group::before, .btn-toolbar::after, .btn-toolbar::before, .clearfix::after, .clearfix::before, .container-fluid::after, .container-fluid::before, .container::after, .container::before, .dl-horizontal dd::after, .dl-horizontal dd::before, .form-horizontal .form-group::after, .form-horizontal .form-group::before, .modal-footer::after, .modal-footer::before, .modal-header::after, .modal-header::before, .nav::after, .nav::before, .navbar-collapse::after, .navbar-collapse::before, .navbar-header::after, .navbar-header::before, .navbar::after, .navbar::before, .pager::after, .pager::before, .panel-body::after, .panel-body::before, .row::after, .row::before {
        content: none;
    }
}

@media screen and (width: 667px) {
    .feature-grid {
        height: 200px;
    }
    .feature-grid > img {
        height: 180px;
    }
    .feature-grid > h2 {
        font-size: initial;
    }
}

@media screen and (width: 926px) {
    .feature-grid {
        height: 334px;
    }
}

@media screen and (width: 896px) {
    .feature-grid {
    height: 325px;
    }
}