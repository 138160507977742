video {
    object-fit: cover;
    height: 780px;
    width: 100%;

    position: absolute;
    top: 0px;
    left: 0;
    }

.video-wrapper {
    /* Telling our absolute positioned video to 
    be relative to this element */
    /* position: relative; */

    width: 100%;
    height: 780px;

    /* Will not allow the video to overflow the 
    container */
    overflow: hidden;

    /* Centering the container's content vertically 
    and horizontally */
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    }

@media (min-width: 1700px) {
    .video-wrapper {
        width: 1920px;
        height: auto;
        position: relative;
        right: 390px;
    }

    video {
        height: auto;
        width: 1920px;
        position: relative;
    }
}

@media (max-width: 1680px) {
    .video-wrapper {
        width: 1680px;
        height: auto;
        position: relative;
        right: 270px;
    }

    video {
        height: auto;
        width: 1680px;
        position: relative;
    }
}

@media (max-width: 810px) {
    .video-wrapper {
        width: 100%;
        height: 600px;
        right: 0;
    }

    #video-container {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }

    video {
        width: 100%;
        height: 600px;
    }
}

.video-wrapper {
    border-bottom: 5px black;
    margin-bottom: -5px;
}

#video-container {
    border-bottom: 5px black;
    margin-bottom: -5px;
}