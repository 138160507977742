/* ServiceSectionGridItem.css */

h2 {
    font-family: Gotham !important;
    text-transform: uppercase;
}

/* Container for the grid items */
.service-grids {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; /* Gap between grid items */
}

/* Each grid item */
.grid-item {
    width: calc(33.333% - 20px); /* 3 items per row, with spacing */
    text-align: -webkit-center;
    margin-bottom: 20px;
    align-content: space-evenly;
}

/* Grid item image */
.grid-item img {
    max-width: 66%;
    height: auto;
    display: block;
}

/* Grid item title */
.grid-item h3 {
    margin-top: 10px;
}

/* Video Overlay */
.video-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-frame {
    width: 80vw;
    height: 80vh;
    background-color: #000;
}

.video-frame iframe {
    width: 100%;
    height: 100%;
    border: none;
}

/* Media Query for Mobile (less than 480px wide) */
@media screen and (max-width: 911px) {
    .grid-item {
        width: calc(50% - 20px); /* 1 item per row */
    }

    .service-grids {
        padding-left: 0px;
    }

    h2 {
        padding-bottom: 2em;
    }
}

/* Modal Overlay */
.video-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden; /* Prevents content overflow */
}

/* Video Popup */
.video-popup {
    width: 80%;
    height: auto;
    background-color: #000; /* Add background to video popup for clarity */
    position: relative;
    margin-bottom: auto;
    margin-top: 100px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

/* Video */
.video-popup video {
    width: 100%; /* Full width of the popup container */
    height: auto;
    border-radius: 8px;
    max-height: 80vh; /* Limit video height to 80% of the viewport */
}

/* Media Query for Tablets (less than 768px wide) */
@media screen and (max-width: 850px) {
    #co-jsme-tocili h2 {
        margin-bottom: -25px !important;
    }
    .grid-item {
        width: calc(50% - 20px); /* 2 items per row */
    }

    .video-popup {
        padding-bottom: 0px;
    }
}