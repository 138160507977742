.team-grids h1 {
    color: #fff;
    font-family: Gotham;
}

.team-grids p {
    color: #fff;
    font-family: Gotham Book;
}

.team-section::before {
    content: "";
    position: absolute;
    background-color: #fa5235;
    height: 50px;
    width: 100%;
    left: 0;
    z-index: 1;
}

.team-section::after {
    content: "";
    position: absolute;
    background-color: #fa5235;
    height: 50px;
    width: 100%;
    left: 0;
    z-index: 1;
}

.team-section::before {
    top: 0;
    transform: skewY(1.5deg); /* Diagonal shape on the top */
    transform-origin: top right;
}

.team-section::after {
    bottom: 0;
    transform: skewY(1.5deg); /* Diagonal shape on the bottom */
    transform-origin: bottom left;
}

.team-section {

    position: relative;
    z-index: 2; /* Ensure the Swiper content is above the red background */
    background-color: #fa5235;
}

/* Optional: Padding for content within the swiper to prevent overlap */
.team-section {
    padding-top: 0px !important; /* Adjust padding if needed */
    padding-bottom: 95px; /* Adjust padding if needed */
    margin-top: 20px;
}

.team-section h2 {
    padding: 20px 0 40px 0;
    font-family: Gotham !important;
    text-transform: uppercase;
    color: #000 !important;
}

@media screen and (min-width: 900px) and (max-width: 991px) {
    #kdo-jsme .swiper-wrapper {
        left: 25px;
    }
}

@media screen and (max-width: 767px) {
    .team-section::after {
        height: 55px;
    }
    .team-section h2 {
        padding: 20px 0 20px 0;
    }
}

@media screen and (max-width: 800px) {
    .hero-style-2 {
        min-height: 400px;
    }

    .btn-group-vertical > .btn-group::after, .btn-group-vertical > .btn-group::before, .btn-toolbar::after, .btn-toolbar::before, .clearfix::after, .clearfix::before, .container-fluid::after, .container-fluid::before, .container::after, .container::before, .dl-horizontal dd::after, .dl-horizontal dd::before, .form-horizontal .form-group::after, .form-horizontal .form-group::before, .modal-footer::after, .modal-footer::before, .modal-header::after, .modal-header::before, .nav::after, .nav::before, .navbar-collapse::after, .navbar-collapse::before, .navbar-header::after, .navbar-header::before, .navbar::after, .navbar::before, .pager::after, .pager::before, .panel-body::after, .panel-body::before, .row::after, .row::before {
        content: " ";
    }

    .btn-group-vertical > .btn-group::after, .btn-group-vertical > .btn-group::before, .btn-toolbar::after, .btn-toolbar::before, .clearfix::after, .clearfix::before, .container-fluid::after, .container-fluid::before, .container::after, .container::before, .dl-horizontal dd::after, .dl-horizontal dd::before, .form-horizontal .form-group::after, .form-horizontal .form-group::before, .modal-footer::after, .modal-footer::before, .modal-header::after, .modal-header::before, .nav::after, .nav::before, .navbar-collapse::after, .navbar-collapse::before, .navbar-header::after, .navbar-header::before, .navbar::after, .navbar::before, .pager::after, .pager::before, .panel-body::after, .panel-body::before, .row::after, .row::before {
        content: " ";
    }
}