.site-footer .upper-footer {
    background-color: transparent;
    padding: 40px 0;
}

#socials-icons a {
    font-size: 50px;
    text-decoration: none;
    /* margin-right: 48px; */
}

@media (min-width: 750px) {
    #socials-icons {
        /* padding-left: 163px; */
        padding-top: 133px;
    }
}

@media (min-width: 1200px) {
    .col-lg-3 {
        width: 25% !important;
    }
}

.socials-icon {
    width: 50px;
}

.site-footer {
    background-color: transparent !important;
    color: #000 !important;
    font-family: "Gotham Book";
}

.about-widget p {
    color: #000;
}

.widget-title h3 {
    font-family: Gotham !important;
    text-transform: uppercase !important;
    min-height: 30px;
}
.site-footer .widget-title h3{
    font-size: 30px !important;
}
.site-footer p, .site-footer a {
    color: #000 !important;
}

.site-footer a {
    text-decoration: underline;
    max-width: 100px;
}

.site-footer .contact-map iframe {
    height: 280px;
}

#social-icons > img {
    max-width: 100px;
}