video {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: unset;
    width: 100%;
    }
.video-wrapper {
        /* border: 2px solid #000; */
        width: 100%;
        height: 200px;
        position: unset;
        overflow: hidden;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

.site-header #navbar > ul > li > a {
    font-family: Gotham;
    font-size: 0.9em !important;
    color: #fff;
    padding-right: 10px !important;
    padding-top: 30px !important;
}

@media (max-width: 911px) {
    .site-header #navbar > ul > li > a {
        color: #000 !important;
        padding-top: unset;
    }
}

@media (min-width: 911px) {
    .open-btn {
        left: 20vh !important;
    }
    .site-header #navbar .navbar-nav {
    margin-top: 20px !important;
    }
}

.site-header #navbar > ul li a:hover, .site-header #navbar > ul li a:focus {
    font-family: "Gotham Book";
    font-size: 1em !important;
    color: #000 !important;
}

.navbar-header {
    padding-left: 30px;
}

.site-header #navbar > ul li a:hover {
    font-family: "Gotham Ultra";
}

.site-header #navbar > ul li a {
    font-family: "Gotham Book";
}

.language-buttons {
    display: flex;
    padding-top: 2.3vh;
    padding-left: 3vw;
}

#language-changers {
    background-color: transparent;
    border: 0px;
    color: #fff;
    padding-top: 26px !important;
    padding-left: 60px !important;
    /* width: 250px !important; */
}

@media (max-width: 991px) {

    #language-changers {
        padding-left: 15px !important;
    }
    #lch1 {
        display: inline !important;
        padding: 40px 20px !important;
    }
    #lch2 {
        display: inline !important;
        padding: 40px 0px !important;
    }
    #lch3{
        display: inline !important;
        padding: 40px 0px !important;
    }
    
}

#lch1 {
    display: inline;
    padding: 40px 20px !important;
}
#lch2 {
    display: inline;
    padding: 40px 0px !important;
}
#lch3{
    display: inline;
    padding: 40px 0px !important;
}


.language-changeraa {
    display: inline;
    background-color: transparent;
    border: 0px;
    color: #fff;
    font-family: "Gotham Book";
    font-weight: 600;
    /* padding: 0 !important; */
}

@media(max-width: 911px) {
    .language-buttons {
        padding-left: 15vw;
    }

    .language-changer {
        padding-top: 2vh;
        padding-left: 2vw;
        color: #000;
        font-size: small;
        font-weight: 300;
    }
}