#kdo-jsme {
    --kdo-jsme-height: 450px;
    --kdo-jsme-width: 300px;
}

#kdo-jsme .slide-inner {
    width: var(--kdo-jsme-width);
    height: var(--kdo-jsme-height);
}

#kdo-jsme .slide-inner:hover {
    border: 5px solid #fff;
}

#kdo-jsme .hero-slider .swiper-container {
    padding-left: 0px;
}
#kdo-jsme .slide-bg-image {
    height: var(--kdo-jsme-height);
}
#kdo-jsme .slide-content {
    height: var(--kdo-jsme-height);
}
#kdo-jsme .swiper-wrapper {
    height: var(--kdo-jsme-height);
}

.slide-bg-image:hover .slide-content {
    opacity: 1; /* Show content on hover */
}

.slide-bg-image:hover {
    filter: brightness(66%); /* Darken image on hover */
}

.slide-title, .slide-text, .slide-btns {
    text-align: center;
    margin: 10px 0;
}

.slide-btns .theme-btn {
    padding: 10px 20px;
    background-color: #ff6600;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
}

.slide-btns .theme-btn:hover {
    background-color: #e65c00; /* Slightly darker on hover */
}

@media screen and (min-width: 1200px) {
    #kdo-jsme .swiper-wrapper {
        padding-left: 27px;
    }
}

@media screen and (max-width: 926px) {
    #kdo-jsme {
        --kdo-jsme-height: 500px;
        --kdo-jsme-width: 100%;
    }
}