.slide-inner {
    position: relative;
    overflow: hidden;
    color: white !important;
}

.slide-bg-image {
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    position: relative;
    transition: all 0.4s ease-in-out; /* Smooth transition for darkening */
}

.slide-content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.4s ease-in-out; /* Smooth transition for text appearance */
    color: white; /* Ensure text is visible on dark background */
}

.slide-title, .slide-text, .slide-btns {
    text-align: center;
    margin: 10px 0;
}

.slide-btns .theme-btn {
    padding: 10px 20px;
    background-color: #fff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
}

.slide-btns .theme-btn {
    background-color: transparent;
    border: 2px solid #fff;
}

.hero-slider {
    position: relative;
    overflow: visible;
    background-color: #000;
}

.hero-slider h2 {
    color: #fa5235;
    text-transform: uppercase;
    font-family: Gotham;
    margin-bottom: 50px;
    text-align: left;
    z-index: 10;
    position: relative;
}

#kde-vse-vznika::before {
    content: "";
    position: absolute;
    background-color: #000;
    height: 122px;
    width: 100%;
    left: 0;
    z-index: 1;
    transform: skewY(-2deg);
    transform-origin: top right;
    top: -120px;
}

#kde-vse-vznika::after {
    content: "";
    position: absolute;
    background-color: #000;
    height: 100px;
    width: 100%;
    left: 0;
    z-index: 1;
    transform: skewY(3deg);
    transform-origin: top right;
    bottom: -100px;
}

#kde-vse-vznika .swiper-wrapper {
    padding-left: 0px;
}

#kde-vse-vznika .swiper-button-prev {
    left: 0;
}

#kde-vse-vznika .swiper-button-next {
    right: 0;
}

@media screen and (min-width: 911px){
    .hero-style-2 .container {
        position: relative;
        padding-top: 0px;
    }
}

@media screen and (min-width: 1920px) {
    .hero-slider .swiper-slide {
        overflow: visible !important;
    }

    #kde-vse-vznika .swiper-button-prev {
        left: 43px;
    }

    #kde-vse-vznika .swiper-button-next {
        right: 37px;
    }
}

@media screen and (max-width: 1200px){
    .hero-style-2 .container {
        bottom: 90px;
    }
}

@media screen and (max-width: 1000px) {
    #kde-vse-vznika .swiper-button-prev {
        left: 0;
    }
}

@media screen and (max-width: 950px) {
    #kde-vse-vznika .slide-inner {
        width: 100%;
        height: 450px;
    }

    #kde-vse-vznika .hero-slider .swiper-container {
        padding-left: 0px;
    }
    #kde-vse-vznika .slide-bg-image {
        height: 450px;
    }
    #kde-vse-vznika .slide-content {
        height: 450px;
    }
    #kde-vse-vznika .swiper-wrapper {
        height: 450px;
    }
}

@media screen and (max-width: 850px) {
    #kde-vse-vznika .slide-inner {
        width: 100%;
        height: 350px;
    }

    #kde-vse-vznika .hero-slider .swiper-container {
        padding-left: 0px !important;
    }
    #kde-vse-vznika .slide-bg-image {
        height: 350px;
    }
    #kde-vse-vznika .slide-content {
        height: 350px;
    }
    #kde-vse-vznika .swiper-wrapper {
        height: 350px;
    }
}

@media screen and (max-width: 760px) {
    #kde-vse-vznika .slide-inner {
        width: 100%;
        height: 250px;
    }

    #kde-vse-vznika .hero-slider .swiper-container {
        padding-left: 0px !important;
    }
    #kde-vse-vznika .slide-bg-image {
        height: 250px;
    }
    #kde-vse-vznika .slide-content {
        height: 250px;
    }
    #kde-vse-vznika .swiper-wrapper {
        height: 250px;
    }
}

@media screen and (max-width: 820px) {
    .hero-slider .swiper-container {
        right: 0%;
    }
    .hero-slider h2 {
        margin-top: 120px;
    }
}

@media screen and (max-width: 800px){
    .hero-style-2 .container {
        position: relative;
        bottom: 150px;
    }
}

@media screen and (max-width: 767px) {
    .hero-style-2 {
        min-height: 100px;
        padding-bottom: 0px;
        padding-top: 100px;
        margin-top: -100px;
    }
    .hero-slider h2 {
        margin-bottom: 0px;
    }
    #kde-vse-vznika::before {
        height: 42px;
        top: -30px;
    }
    .hero-slider h2 {
        margin-bottom: 0px;
        
    }
    #kde-vse-vznika::after {
        height: 50px;
        bottom: -50px;
    }

    #kde-vse-vznika h2 {
        margin-bottom: -21px;
    }

    .hero-style-2{
        margin-bottom: 0px;
    }

    #kde-vse-vznika .swiper-wrapper {
        padding-left: 0px;
    }
}

@media screen and (width: 926px) {
    #kde-vse-vznika::after {
        height: 50px;
        bottom: -50px;
    }
}

@media screen and (width: 667px) {
    #kde-vse-vznika .slide-inner{
        height: 250px;
    }
    #kde-vse-vznika {
        min-height: 435px;
    }
}