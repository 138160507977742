#ze-skrine {
    padding: 0;
    padding-top: 30px;
    background-color: #fa5235;
}

#ze-skrine::after {
    content: "";
    position: absolute;
    background-color: #fa5235;
    /* background-color: #000; */
    height: 75px;
    width: 100%;
    left: 0;
    z-index: 1;
    transform: skewY(2.2deg);
    transform-origin: bottom left;
    bottom: 433px;
}

@media screen and (min-width: 1620px) {
    #ze-skrine::after {
        bottom: 440px;
    }
}

@media screen and (min-width: 1920px) {
    #ze-skrine::after {
        bottom: 444px;
    }
}

@media screen and (max-width: 1400px) {
    #ze-skrine::after {
        bottom: 431px;
        height: 70px;
    }
}

@media screen and (max-width: 1200px) {
    #ze-skrine::after {
        bottom: 441px;
        height: 50px;
    }
}

@media screen and (max-width: 991px) {
    #ze-skrine::after {
        bottom: 622px;
        height: 50px;
    }
}

@media screen and (max-width: 768px) {
    #ze-skrine::after {
        bottom: 808px;
        height: 50px;
    }
}

@media screen and (max-width: 749px) {
    #ze-skrine::after {
        bottom: 675px;
        height: 50px;
    }
}

@media screen and (max-width: 600px) {
    #ze-skrine::after {
        bottom: 666px;
        height: 50px;
    }
}

@media screen and (max-width: 430px) {
    #ze-skrine::after {
        bottom: 684px;
        height: 25px;
    }
}

@media screen and (max-width: 400px) {
    #ze-skrine::after {
        bottom: 683px;
        height: 25px;
    }
}

@media screen and (max-width: 389px) {    
    #ze-skrine::after {
        bottom: 682px;
        height: 25px;
    }
}

@media screen and (max-width: 360px) {    
    #ze-skrine::after {
        bottom: 682px;
        height: 25px;
    }
}

@media screen and (width: 1080px) {
    #ze-skrine::after {
        bottom: 437px;
    }
}

@media screen and (width: 768px) {
    #ze-skrine {
        padding-top: 50px;
    }
    #ze-skrine::after {
        bottom: 610px;
        height: 50px;
    }
}

@media screen and (width: 667px) {
    #ze-skrine {
        padding: 20px;
        padding-top: 50px;
    }

    #ze-skrine::after {
        bottom: 680px;
        height: 50px;
    }
}